import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Internet_Access/DDNS_Provider/NavButtons';
import ForumBox from 'components/Internet_Access/DDNS_Provider/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "DDNS Provider - No-IP",
  "path": "/Internet_Access/DDNS_Provider/NoIP/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Use a Third Party DDNS Provider with your INSTAR IP Camera",
  "image": "./IA_SearchThumb_ThirdPartyDDNS_noIP.png",
  "social": "/images/Search/IA_SearchThumb_ThirdPartyDDNS_noIP.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-DDNS_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='DDNS Provider - No-IP' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use a Third Party DDNS Provider with your INSTAR IP Camera' image='/images/Search/IA_SearchThumb_ThirdPartyDDNS_noIP.png' twitter='/images/Search/IA_SearchThumb_ThirdPartyDDNS_noIP.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/DDNS_Anbieter/NoIP/' locationFR='/fr/Internet_Access/DDNS_Provider/NoIP/' crumbLabel="No-IP" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "ddns-provider",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#ddns-provider",
        "aria-label": "ddns provider permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DDNS Provider`}</h1>
    <h2 {...{
      "id": "no-ip--managed-ddns-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#no-ip--managed-ddns-service",
        "aria-label": "no ip  managed ddns service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`No-IP :: Managed DDNS Service`}</h2>
    <p>{`How to use your No-IP address with your INSTAR IP camera?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3abe70c47258638440e2a53465473f6a/5a190/No-ip.com_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACRUlEQVQoz1WQW0/aYABAvyJmJjpU3FwRhEgBBUq5OFq7cVMutlUaUGAggrRouZQWSlpDgEWnW9TEPWzL9rqH/YD9xcX4tJPzel4OGN8rTVFKs5VoJp+gC3Hq6H2KjaTYnRhFxim2zDPFOlOoOdEwghJ271ur04840etzL8diwLHlgC3W16Z107p9y4+ncye1rla56BdOLwoVviFPGv1JJJ1bWDa/sWO2QGLNQ1rceCiIenx+8D/Q7Jzh4AOvffnWGozb0qV2/1O5fsTj9AsDvAgjZn8c9kZWvVE4RK1t02AjGLUg6Ixe/5RCEABgYXHlTB432kq92ZM+PvCiZveE51Y29C8Mq66wjWBieS6QzK9hMeAhki4UX14ymmCTD8UwLIBhgZNzqd0fCT318vZrZzAKvktthhKIy+v04etohGDrCMkYnWFwfJgvZvOnparQEIa94WR0NR1/yhVPOpImcJ2bm4duT0XxPbObhM1IuVjNHJQc27smN+EkaVCttxpVvs13REEaytrD58f9FDU/v0iWOPLHb+bPX5I+XjK7DbbgyyVYbsnyYJo8OmMFVbz7Bbj2sNVVL7j2oKfeXt0xGQaCIP3MrCe6T7SU0v13bzg2b7QabajZ4T8TlGJnRAujbHdSnz4CZjedTdHlfGmsTvYSyedtkG7GuuH2udBqtRkk917Z3MHUEcUr6aYaPZWerMlMbwrCqI/0h3KZg/0kBQDQ6XTPz1dWLY5NX4EXD8UpK06z3Ums3t8pd/GKSNQkklMi59o/Q/idme/+HTQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3abe70c47258638440e2a53465473f6a/e4706/No-ip.com_01.avif 230w", "/en/static/3abe70c47258638440e2a53465473f6a/d1af7/No-ip.com_01.avif 460w", "/en/static/3abe70c47258638440e2a53465473f6a/c9875/No-ip.com_01.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3abe70c47258638440e2a53465473f6a/a0b58/No-ip.com_01.webp 230w", "/en/static/3abe70c47258638440e2a53465473f6a/bc10c/No-ip.com_01.webp 460w", "/en/static/3abe70c47258638440e2a53465473f6a/d00b9/No-ip.com_01.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3abe70c47258638440e2a53465473f6a/81c8e/No-ip.com_01.png 230w", "/en/static/3abe70c47258638440e2a53465473f6a/08a84/No-ip.com_01.png 460w", "/en/static/3abe70c47258638440e2a53465473f6a/5a190/No-ip.com_01.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3abe70c47258638440e2a53465473f6a/5a190/No-ip.com_01.png",
              "alt": "Third Party DDNS Provider No-IP",
              "title": "Third Party DDNS Provider No-IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To create a No-IP hostname, you first have to log in and create a user account.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/726d214034d17572ef8de3d67e8058d8/5a190/No-ip.com_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACSElEQVQ4y21UW27bMBDMAdtL9QC9SA7R9gD9aYAmDpDYsiXZepDim9zlkiooWXGCZjAQ9DM7yxlKdwCAMb4nIP7HCJ/hDgDGS9fVTd+0Xd10p4Z3HVqL1oIxYIwXQnGOH3EVY9u6lxez38PphHW90leV3R/C8RjbNp7Pcb93l4tQSgqhlFJaOecWcVUFrV0IkSjlnHKmlLz31rkAQEQ0zySErSomJWfMGOO9DyEs4tPJCiGVEkJorXNKFKOz1hijtQ7e50Xs6nrSuu+6cRyVUt77RXw8Bq1pLsiL88o8z4DIGFPWqrY1VTVME2cF0zRdnaGqzDAYpayUaF30Hp1bCdYqzq0xtml80zjEdeHb2iCE2j1Pj0/i8Unsdn6/x+PxjfF0Wl9K8lvI76pCHKfp0o/dMDaXjgmhjBFaS209IMR45Yaw4NZzKS4WJKIY49qj9dwFkYisMQDgnGXjyDmXUmqjt7QBNEYOMZaMbqAUKME8z2VoStbaYUHRC7mlDaABTYxvsrwgZVpTT6nEb4zp+n4YBsa41tqHTWwQHRGk5Ilo8c8byrCU5pyVlpfuzEbW9/3QD9sNA1CAvQeD0UWKxYcoISVIKZYBRMvaahLlwEoqKbe1S2BEQOu9mPN28pTmMigRAeSUpNJte+YLGGPWWkRcxABLxBgQIubB/D5M93t5P9a/4NDYY+VeX8u3pXUxlVIpdauqtHd9esL5b//95+Hrj/rL88M39Vifd0/TnwcYx9L21vAH8XsU+0ICFwLnwKcwiU9/Bv8AFWcGf2Y2lPEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/726d214034d17572ef8de3d67e8058d8/e4706/No-ip.com_03.avif 230w", "/en/static/726d214034d17572ef8de3d67e8058d8/d1af7/No-ip.com_03.avif 460w", "/en/static/726d214034d17572ef8de3d67e8058d8/c9875/No-ip.com_03.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/726d214034d17572ef8de3d67e8058d8/a0b58/No-ip.com_03.webp 230w", "/en/static/726d214034d17572ef8de3d67e8058d8/bc10c/No-ip.com_03.webp 460w", "/en/static/726d214034d17572ef8de3d67e8058d8/d00b9/No-ip.com_03.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/726d214034d17572ef8de3d67e8058d8/81c8e/No-ip.com_03.png 230w", "/en/static/726d214034d17572ef8de3d67e8058d8/08a84/No-ip.com_03.png 460w", "/en/static/726d214034d17572ef8de3d67e8058d8/5a190/No-ip.com_03.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/726d214034d17572ef8de3d67e8058d8/5a190/No-ip.com_03.png",
              "alt": "Third Party DDNS Provider No-IP",
              "title": "Third Party DDNS Provider No-IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You have to set a user name and password first. Then type in a host you want to use for your camera and choose a domain from the drop down menu.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5fc37de9480d0dc864e41951e6ebb3e0/5a190/No-ip.com_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB/0lEQVQoz42QW2/TQBCF/bdBAvWRl0CFRMUTgqI+VIJKqJAoatL0RpMmbZKSBtxcrDSOHbuOXe96r971Dkr7A+DT0Whe5pyjsQSjgmDBmZRSyPw+wV6YRAmG/8CiKEHBnBGc50pI7oZ3bugGsS8V//cxzrJoFSfJA2M8lzIMl0Hg+57n+X6aIs45Z09DUCFZrrgQnDHO1tYWybIkXkMp1UrnIi9UTgmVlGuR50oZUwCAktJgDBgpxgpjDJjHZISjaMUY42JtNlwuq8MxAAyW8/poBADOIuqOZoDQrNPpHjYhSmLPnk1vCwNWlmUoTSkljFIA07m1y+1LUKp9O9y/PAVVXNuTw/4VxHH3olU7aRXhvTPsn190dWEsP4hubGd65w1sBxMCwAtDDWhY6+lnwoAAKYvFGFwbGJVAmPLXtedeWD3t/ZkuUoTdYPV6u1L6VC59/PHy3ZcXW3vP3+6++VyptYavPuxv7lRL25WN93sbW1+fbe7ufD+z/DC6uhlf206UpEzI4/ag0byuN/uVk8uD41a10TzrDKbzZf28f9T6VfvZqxx1yo32t3qr99uxkjhZzOeeu/BcL8OZ5IxgRDIMSoDEwDEobkxB0/ghXmUYacGMyIpcrGtH99HYHk8nzng0CfyAEJKmCCEkOBdScs4Fo1oX8hGllNZaaf20/AX8A1f06NGrqgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5fc37de9480d0dc864e41951e6ebb3e0/e4706/No-ip.com_04.avif 230w", "/en/static/5fc37de9480d0dc864e41951e6ebb3e0/d1af7/No-ip.com_04.avif 460w", "/en/static/5fc37de9480d0dc864e41951e6ebb3e0/c9875/No-ip.com_04.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5fc37de9480d0dc864e41951e6ebb3e0/a0b58/No-ip.com_04.webp 230w", "/en/static/5fc37de9480d0dc864e41951e6ebb3e0/bc10c/No-ip.com_04.webp 460w", "/en/static/5fc37de9480d0dc864e41951e6ebb3e0/d00b9/No-ip.com_04.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5fc37de9480d0dc864e41951e6ebb3e0/81c8e/No-ip.com_04.png 230w", "/en/static/5fc37de9480d0dc864e41951e6ebb3e0/08a84/No-ip.com_04.png 460w", "/en/static/5fc37de9480d0dc864e41951e6ebb3e0/5a190/No-ip.com_04.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5fc37de9480d0dc864e41951e6ebb3e0/5a190/No-ip.com_04.png",
              "alt": "Third Party DDNS Provider No-IP",
              "title": "Third Party DDNS Provider No-IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You will be asked to confirm your account by an email that is send to you. After you are logged in, open the Manage Hosts link and check if your hostname was set up correctly.`}</p>
    <p><img parentName="p" {...{
        "src": "./No-ip.com_5.png",
        "alt": "Third Party DDNS Provider No-IP"
      }}></img></p>
    <p>{`To use your `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/QNAP/"
      }}>{`QNAP`}</a>{` or `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/Synology/"
      }}>{`Synology`}</a>{` surveillance station to keep your new No-IP address up-to-date, go to System/Network/DDNS Settings, choose `}<a parentName="p" {...{
        "href": "http://www.noip.com"
      }}>{`www.noip.com`}</a>{` from the dropdown menu and type in your account information. The surveillance station will now update your No-IP address every time your internet service provider assigns you a new WAN IP address.`}</p>
    <p><img parentName="p" {...{
        "src": "./No-ip.com_7.png",
        "alt": "Third Party DDNS Provider No-IP"
      }}></img></p>
    <p>{`To use your AVM Fritzbox router to keep your new No-IP address up-to-date, go to Internet/Freigaben, choose `}<a parentName="p" {...{
        "href": "http://www.noip.com"
      }}>{`www.noip.com`}</a>{` from the dropdown menu and type in your account information. Your router will now update your No-IP address every time your internet service provider assigns you a new WAN IP address.`}</p>
    <p><img parentName="p" {...{
        "src": "./No-ip.com_6.png",
        "alt": "Third Party DDNS Provider No-IP"
      }}></img></p>
    <p>{`To use your DD-WRT router to keep the No-IP address up to date, go to Setup/DDNS, select Custom from the drop-down menu and type in your user information from the steps above. The HASH key used here of the update URL can be found in the dashboard of your No-IP website. Alternatively, you can use your username and password. Your router will now update the No-IP address every time your Internet Service Provider has assigned you a new WAN address.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      